import BurgerMenu from "./../BurgerMenu";

function Header({ logo, burgerMenu }) {
  return (
    <div className="header__wrapper">
      <header className={`header ${logo && burgerMenu ? "jcb" : "jce"}`}>
        {logo && (
          <div className="logo">
            <img src={logo} alt="Restaurant logo" />
          </div>
        )}
        <div className="header__navigation header__navigation--grey">
          {/*TODO add language picker and burger menu*/}
          {burgerMenu?.is_restaurant_burger_menu_enabled && <BurgerMenu menu={burgerMenu} />}
        </div>
      </header>
    </div>
  );
}



export default Header;
