import closeBtn from "../../assets/images/svg/btn_close.svg";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";

// TODO pass social media links from API
function BurgerMenu({ menu }) {
  //фиксану шрифт в меню в украинецькій мові 
  const [isMenuOpened, setMenuOpenedState] = useState(false);
  const { restaurant_site_url, restaurant_facebook, restaurant_instagram } = menu;
  const openMenu = () => {
    document.body.style.overflow = 'hidden';
    setMenuOpenedState(true);
  }

  const closeMenu = () => {
    document.body.style.overflow = 'auto';
    setMenuOpenedState(false);
  }

  return (<div className="burger-menu">
    <div className="burger-menu__icon" id="burger-menu__icon" onClick={openMenu}>
      <div className="burger-menu__line" />
      <div className="burger-menu__line" />
      <div className="burger-menu__line" />
    </div>
    <div className={isMenuOpened ? 'menu menu--opened' : 'menu'} id="menu">
      <div className="menu__btn-close" id="menu__btn-close" onClick={closeMenu}>
        <img src={closeBtn} alt="Close button" />
      </div>
      <div className="menu__content">
        {restaurant_site_url && (
          <div className="menu__item">
            <FeatherIcon icon="globe" />
            <a href={restaurant_site_url} target="_blank" className="menu__item_link">Наш веб-сайт</a>
          </div>
        )}
        {restaurant_facebook && (
          <div className="menu__item">
            <FeatherIcon icon="facebook" />
            <a href={restaurant_facebook} target="_blank" className="menu__item_link">Facebook</a>
          </div>
        )}
        {restaurant_instagram && (
          <div className="menu__item">
            <FeatherIcon icon="instagram" />
            <a href={restaurant_instagram} target="_blank" className="menu__item_link">
              Instagram
            </a>
          </div>
        )}
      </div>
    </div>
  </div>)
}

export default BurgerMenu;
