import React, { useState, useEffect } from 'react';
import Menu from "./../Menu";
import Loading from "./../Loading";
import config from "../../settings";
import { useParams } from "react-router-dom";
import '../../css/header.css';
import '../../css/main-menu.css';
const axios = require('axios');
const apiUrl = config.services.orryAPI.public;

function MainContainer() {
  let [loading, setLoading] = useState(false);
  let [menuContent, setMenuContent] = useState(null);
  let [burgerMenu, setBurgerMenu] = useState(null);
  let [logo, setLogo] = useState(null);
  let [disabled, setDisabled] = useState(false);
  const { restaurantId, menuId } = useParams();
  useEffect(() => {
    async function fetchMenu() {
      const menuData = await axios({
        method: 'GET',
        url: `${apiUrl}/wp-json/wp/v1/menu-content?restarant_id=${restaurantId}&menu_id=${menuId}`
      }).then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
      }).catch(function (error) {
        console.log('catch ', error);
      });

      if (!Array.isArray(menuData)) {
        setDisabled(menuData.disabled);
        setLogo(menuData.previewUrl);
        setMenuContent(menuData.menuContent);
        setBurgerMenu(menuData.burgerMenu);
      }
    }
    setLoading(true);
    fetchMenu();
    setLoading(false);
  }, [restaurantId, menuId])
  return (
    <>
      <div className="App">
        {loading ? <Loading logo={logo} /> : <Menu disabled={disabled} logo={logo} menuContent={menuContent} burgerMenu={burgerMenu} />}
      </div>
    </>
  );
}

export default MainContainer;
